import { Flex, Heading, Icon, Image, Separator, Text } from "@chakra-ui/react";
import { FaArrowDown } from "react-icons/fa6";

const content = "I have a BS in Computer Science from the University of Maryland, College Park with a minor in General Business. I also have experience building software for various companies, and am always looking for ways to expand my skillset. Click through the tabs to view my resume, experience, and to contact me with inquiries."

function HomePage() {
    const isMobile = window.innerWidth <= 786;

    return (
        <>
            {isMobile ? MobileContent() : DesktopContent()}
        </>
    );
}

function DesktopContent() {
    console.log(window.innerWidth);
    return (
        <Flex direction="column" height="100%" width="100%" overflowX="hidden">
            <Flex direction="row" height="93.5vh" minHeight="800px" minWidth="1000px">
                <Flex flex={1} p={12}>
                    <Image src="/documents/portrait.jpeg" borderRadius="full" />
                </Flex>
                <Flex direction="column" flex={2} justify="center" align="center" fontFamily="body">
                    <Heading size="7xl" p={6} textAlign="center">My name is Saipraneeth,</Heading>
                    <Heading size="4xl" textAlign="center">but they call me Neeth</Heading>
                    <Flex direction="column" p={20}>
                        <Icon animation="bounce" fontSize="75px">
                            <FaArrowDown size="2xl" />
                        </Icon>
                    </Flex>
                </Flex>
            </Flex>
            <Separator />
            <Flex p={10}>
                <Text fontSize="3xl" textAlign="center">{content}</Text>
            </Flex>
        </Flex>
    );
}

function MobileContent() {
    console.log(window.innerWidth);
    return (
        <Flex direction="column" height="100%" width="100%">
            <Flex direction="column" height="93.5vh">
                <Flex flex={1} p={12}>
                    <Image src="/documents/portrait.jpeg" borderRadius="full" />
                </Flex>
                <Flex direction="column" flex={2} justify="center" align="center" fontFamily="body">
                    <Heading size="7xl" p={6} textAlign="center">My name is Saipraneeth,</Heading>
                    <Heading size="4xl" textAlign="center">but they call me Neeth</Heading>
                    <Flex direction="column" p={20}>
                        <Icon animation="bounce" fontSize="75px">
                            <FaArrowDown size="2xl" />
                        </Icon>
                    </Flex>
                    <Separator />
                    <Flex p={10}>
                        <Text fontSize="3xl" textAlign="center">{content}</Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default HomePage;